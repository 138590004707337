.new_arrival_width_div{
   padding-left: 5%;
   padding-bottom: 5%;
   padding-right: 5%;
}

.newarrivalimges{
    /* height: 310px !important; */
    border-radius: 10px;
}


.inputStyle {
    width: 10% !important;
    height: 45px;
    border-radius: 7px;
    border: 0px;
    margin-left: 8px;
    margin-right: 8px;
    background: #dddddd;
    font-size: 20px;
    justify-content: center;
    /* text-align: center; */
  }
  .otp {
    display: flex;
    justify-content: center !important;
/* margin: auto;
    width: 100%;
    align-items: center; */
  }
.eyeicon {
  transform: translateX(-30px);
  color: lightgray;
}

.changepasswordinputtxt {
  width: 60%;
  border-radius: 5px;
  border: 1px solid gray;
  padding: 8px;
}

.changepasswordbtn {
  background-color: black !important;
  font-family: CamptonBook !important;
  color: white !important;
  font-weight: bolder !important;
text-transform: capitalize !important;
  border-radius: 5px !important;
  letter-spacing: 0.00938em !important;
}

.Mui-disabled {
  color: white !important;
  background-color: gray !important;
}

.cha_p{
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}

.click_pointer{
  cursor: pointer;
}

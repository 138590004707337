.banner_image2{
    scroll-snap-align: start;
    padding-left:3% ;
    padding-right: 3%;
    /* padding-top: 2%; */
    padding-bottom: 2%;
}

.banner_3imge{
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    border-radius: 10px;


}


.swiper_centre_div{

    padding-bottom: 2.5%;
    
    }
    .banner_image_swipper{
        min-width: 100%;
        max-width: 100%;
        min-height: 100%;
        max-height: 100%;
     border-radius: 10px;
      
    }









.new_arrival_width_div{
   padding-left: 5%;
   padding-bottom: 5%;
   padding-right: 5%;
   position: relative;
   /* max-height: 100vh; */
   /* overflow-y:scroll; */
   -ms-overflow-style: none;  
   scrollbar-width: none;  
 }
.new_arrival_width_div::-webkit-scrollbar {
   display: none;
 }
 
 .card_boday_down_blog{
   padding: 10px 0px !important;
}

 .list_body_all_offerzoone{

     box-shadow: none !important; 
   background-color:  rgb(211, 206, 206); 
}  
    
   


   .list_body_all_offerzoone:hover{
    box-shadow: 0 5px 15px rgb(0 0 0 / 8%) !important;
    transform: translateY(-10px) !important;
}




.flbrand_blog{
font-size: .9rem;
line-height: 1.1rem;
font-family: CamptonBook;

color: rgba(33,33,33,1);
text-transform: capitalize;
text-align: left;}


.blog_image_list{
   border-top-left-radius: 10px;
border-top-right-radius: 10px;

   width: 100%;
   height: 250px;
   object-fit: cover;
}

.blog_body{
   padding-right: 0px !important;
   padding-left: 0px !important;
}

.blogdetails_padding{
   padding-left: 7%;
   padding-right: 7%;
   padding-bottom: 7%;
   text-align: left;
   
}

.filtersection{padding-left: 15px;
text-align: left;
padding-top: 2%;

 position: sticky; 
 top: 120px;

}
.categoryfil{padding-left: 5px !important;
font-size: .70rem !important;
color: rgba(33,33,33,1) !important;
text-decoration: none !important;
font-family: CamptonBook !important;
text-transform: uppercase ;
}
.checkboxstyle{width: 11px !important;
height: 11px !important;}

.list_gap{
    padding-top: 10px;
   
}
#filter_cursor{
    cursor: pointer;
    text-transform: uppercase;
    margin-left: 13px !important;
}
/************************/
.filcatetx1{
    text-align: left;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: bolder;
    font-family: CamptonBook;
  
}
.categort_ui_list{
    padding-inline-start: 0px !important;
}
input[type=checkbox] {
    accent-color: black;
  }
  .catfill_id{
    padding-left: 0px !important;
    font-size: 1.1rem !important;
    margin-left: 20px;
    border-radius: 50%;
    color: rgba(33,33,33,1) !important;
    font-family: CamptonBook;
  }

  .categoryfil1{padding-left: 20px !important;
    font-size: 1.1rem !important;
    margin-left: 10px;
    border-radius: 50%;
    color: rgba(33,33,33,1) !important;
    font-family: CamptonBook;
 }

    .btnforallfilter{
        border: none !important;
        background-color: transparent;
    }


    .filters{
        font-size:1.2rem;
        font-weight: bolder;
        font-family: CamptonBook;
    }

    .clearall{
        border: none !important;
        background-color: transparent;
        font-size: .9rem;
        float: right;
        color: black !important;
        text-transform: none !important;
    }

    .label{
        list-style: none !important;
    }


    .filter_bottom {
 position: fixed;
        bottom: 0;
        left: 0;
        color: white !important;
        padding: 5px 20px;
        max-width: 100%;

        height: 80px;
        background: black;
      }
      #responsiveprice{padding-bottom: 100px;}
      @media screen and (max-width:426px) {
        

#filter_cursor {
    margin-left: 0px !important; 
      }
    }

      